<template>
  <div class="setting-page">
    <Header />
    <RowLink class="links" :links="links"/>
    <Button class="logout-button" size="large" @click="handleLogout">退出登陆</Button>
  </div>
</template>

<script>
import * as user from '@/utils/user';
import Button from '@/components/common/button/CustomButton';
import Header from '@/components/header';
import RowLink from '@/components/select/rowLink';
export default {
  components: {
    Button,
    Header,
    RowLink,
  },
  data() {
    return {
      links: [
        { label: '我的团队', path: '/shidanliBusiness' },
        { label: '修改密码', path: '/setting/changePassword' },
        { label: '用户反馈', path: '/setting/feedback' },
      ]
    }
  },
  methods: {
    handleLogout() {
      user.logout();
      window.location.replace('/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-page {
  min-height: 100vh;
  background-color: #FAFBFC;
  .links {
    margin-top: 12px;
  }
  .logout-button {
    width: 351px;
    border-radius: 10px;
    margin-top: 54px;
    font-weight: bold;
  }
}
</style>